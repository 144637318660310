.category-tab-card {
    transition-duration: 0.4s;
    padding: 0.8rem;
    margin: 0.6rem;
    border-radius: 20px;
    background-color: rgb(250, 250, 250);
}

.category-tab-card:hover {
    transform: translateX(-6px);
    box-shadow: 7px 7px 55px -45px rgba(0,0,0,0.75);
}

.green-bg-tab {
    border: solid 2px rgba(4,217,196,0.6);
}

.red-bg-tab {
    border: solid 2px rgba(242,100,68,0.6);
}

#green-dot {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 50px;
	background-color: rgba(4,217,196,0.6);
}

#red-dot {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 50px;
	background-color: rgba(242,100,68,0.6);
}

.nav-tabs a {
	color: #000000 !important;
}

.nav-tabs a:hover {
	color: #50b4f2 !important;
}

.nav-tabs .active {
	color: #50b4f2 !important;
}