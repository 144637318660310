/* Remove bootstrap blue outline */
input:focus,
input:active,
textarea:focus,
textarea:active  {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus,
.btn:active,
.page-link {
  outline: none !important;
  box-shadow: none !important;
}

html,
body {
  font-family: 'Encode Sans', sans-serif !important;
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #fff;
  scroll-behavior: smooth;
}

#root {
  margin: 0;
  width: 100%;
  overflow: hidden;
}

:target {
  display: block;
  position: relative;
  top: -145px;
  visibility: hidden;
}

.round {
  border-radius: 30px !important;
}

.details-wrapper {
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  text-align: left;
  border-radius: 20px;
  padding: 2rem 3rem 0.5rem 3rem;
}

.details-image {
  transform: translateY(2.5rem);
}

.details-span {
  font-weight: bold;
  background-color: #50b4f2;
  color: #f1f1f1;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
  border-radius: 30px;
}

.details-description {
  margin-top: 1rem;
  background-color: #e4e4e4;
  padding: 1rem;
  border-radius: 15px;
}

.details-url {
  font-weight: bold;
  background-color: #50b4f2;
  color: #f1f1f1;
  padding: 0.1rem 0.5rem 0.2rem 0.5rem;
  border-radius: 30px;
}

.details-url:hover {
  color: #f1f1f1;
  text-decoration: none;
  background-color: #84c4ec;
}

@media screen and (max-width: 765px) {
  .details-image {
    display: none;
  }
  .details-row {
    margin-top: 1.1rem;
  }
  .details-username {
    margin-left: 0 !important;
  }
}

.grecaptcha-badge {
    z-index:999;
}

/* Font classes*/

.display-font {
  font-family: 'Goldman', cursive !important;
}

/* General links */

.alert-link {
  transition: 0.3s;
  color: #50b4f2;
  font-weight: normal;
}

.alert-link:hover {
  color: #0583f2;
  text-decoration: none;
}

/* Publications temporary 100vh, when content gets added it can be removed */

#mainpage-publications {
  height: 70vh;
  min-height: 100%;
}

/* Bot Datasets page */

#mainpage-bot_datasets {
  min-height: 100%;
  height: 70vh;
}

/* Header - Hero Image */
#header {
  background: url(assets/images/bg_website_bl_lg.jpg) center top / cover
  no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 100vh;
  width: 100%;
  min-height: auto;
}

#header-title h1 {
  color: white;
  font-weight: 700;
}

/* rotation text */

.rotate {
  position: absolute;
  transform: rotate(-90deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  padding-left: 3.4rem;
}

.rotate a {
  color: white;
}

.rotate a:hover {
  text-decoration: none;
  color: #50b4f2;
}

.rotate .alert-link {
  font-family: 'Goldman', cursive;
  font-weight: 700;
}


/* Landing page */

.rotate {
  font-family: 'Goldman', cursive !important;
}

#more {
	animation: upanddown 2.5s infinite linear;
}

@keyframes upanddown {
	0% {
		transform: translateY(0px);
	}
	50% {
		transform: translateY(15px);
	}
	100% {
		transform: translateY(0px);
	}
}

/* Prediction Form */

.prediction-group {
  margin-bottom: 1rem;
}

.prediction-input {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border: 2px solid #919191;
  border-right: none !important;
}

.prediction-input:hover,
.prediction-input:focus {
  border-color: #50b4f2e6 !important;
  background-color: #fff !important;
}

.prediction-btn {
  background-color: #55b9f7e6 !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border: none !important;
}

.prediction-btn:hover {
  background-color: #58bbf8e6 !important;
  border: none !important;
}

/* input validation styling */
.error-input {
  color: #b40e0e !important;
}

.invalid {
  border: 1px solid #b40e0e !important;
  border-right: none !important;
  background-color: #fddddd !important;
}

/* History */
.history-card {
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  text-align: left;
  border-radius: 20px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  overflow: hidden;
  white-space: nowrap;
}

.history-img-col {
  margin-top: 0.3rem;
  flex-grow: 0 !important;
}

.history-btn {
  font-size: 0.8rem !important;
  transition: 0.1s !important;
  background-color: #50b4f2 !important;
  border: none !important;
  border-radius: 20px !important;
  margin-bottom: 0.2rem;
  margin-left: 0.7rem;
}

.history-btn:hover {
  background-color: #58bbf8e6 !important;
  box-shadow: 0px 5px 15px -9px #50b4f2;
}

.history-btn:active {
  transform: translateY(3px);
}

.history-span {
  font-size: 0.8rem;
  padding: 0.1rem;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}
/* Results start */

.section-header {
  /* background-color: #50b4f2; */
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(80,180,242,1) 20%, rgba(80,180,242,1) 50%, rgba(80,180,242,1) 80%, rgba(255,255,255,1) 100%);
}

.section-header h3 {
  font-family: 'Goldman', cursive;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.5rem;
  padding-top: 0.5rem;
}

.results-card {
  text-align: center;
}

.results-header {
  text-align: left;
  margin-top: 3.8rem;
  font-size: 2.3rem;
}

@media screen and (max-width: 600px) {
  .results-header  {
    font-size: 2rem;
  }
}

.results-img {
  margin-bottom: 1rem;
}

.results-ratio-card {
  text-align: center;
  border: 1px solid #b5bbc0;
  border-radius: 20px;
}

.results-btn {
  transition: 0.3s;
  border-radius: 20px !important;
  border: 2px #4f41c5 solid !important;
  color: #4f41c5 !important;
  background-color: transparent !important;
}

.results-btn:hover {
  color: #f1f1f1 !important;
  background-color: #4f41c5 !important;
  box-shadow: 0px 5px 15px -4px #4f41c5 !important;
}

.results-accordion {
  border: none !important;
}

.results-accordion > .card-header,
.collapse.show > .card-body,
.collapsing {
  background-color: #fff !important;
}

.loading-spinner {
  margin-bottom: 5rem;
}

.results-toggle-button {
  font-size: 1.2rem!important;
  padding: 0.5rem 1.5rem!important;
  transition: 0.1s !important;
  border: none !important;
  border-radius: 20px !important;
  background-color: #50b4f2 !important;
}

.results-toggle-button:hover {
  box-shadow: 0px 5px 15px -9px #50b4f2 !important;
}

.results-toggle-button:active {
  transform: translateY(3px);
}

.explanation-type-button-left {
  border-radius: 20px 0 0 20px !important;
}

.explanation-type-button-right {
  border-radius: 0 20px 20px 0 !important;
}


.explanation-type-button-active {
  color: #fff;
  font-size: 1.2rem !important;
  transition: 0.3s !important;
  border: 2px transparent solid !important;
  background-color: #50b4f2 !important;
  cursor: not-allowed !important;
}

.explanation-type-button-inactive {
  color: #50b4f2 !important;
  font-size: 1.2rem!important;
  transition: 0.3s !important;
  border: 2px #50b4f2 solid !important;
  background-color: transparent !important;
}

.explanation-type-button-inactive:hover {
  color: #fff !important;
  background-color: #50b4f2 !important;
}

/* .explanation-type-button-inactive:active {
  transform: translateY(3px);
} */

/* Results end */

/* media queries start */
/* #header {
  background-attachment: fixed !important;
} */

/* Mobile arrow */
.mobile-arrow {
  color: #fff;;
  font-size: 4rem;
	animation: upanddown 2.5s infinite linear;
  padding-bottom: 2rem;
}

/* iphone/ipad queries */

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    #header {
      background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
        no-repeat;
        background-attachment: scroll!important;
    }
    #header-title {
      padding-top: 75vh;
      text-align: center;
      margin: auto;
    }
    #more {
      display: none !important;
    }
    #header-in {
      height: 28vh;
    }
    #header-in-title {
      padding-top: 12vh;
      text-align: center;
    }
    #header-in-title h1 {
      font-size: 2em;
    }
    button {
      text-align: center;
    }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    #header {
      background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
        no-repeat;
        background-attachment: scroll!important;
    }
    #header-title {
      padding-top: 75vh;
      text-align: center;
      margin: auto;
    }
    #more {
      display: none !important;
    }
    #header-in {
      height: 28vh;
    }
    #header-in-title {
      padding-top: 12vh;
      text-align: center;
    }
    #header-in-title h1 {
      font-size: 2em;
    }
    button {
      text-align: center;
    }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {
    #header {
      background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
        no-repeat;
        background-attachment: scroll!important;
    }
    #header-title {
      padding-top: 80vh;
      text-align: center;
      margin: auto;
    }
    #more {
      display: none !important;
    }
    #header-in {
      height: 20vh;
    }
    #header-in-title {
      padding-top: 12vh;
      text-align: center;
    }
    #header-in-title h1 {
      font-size: 2em;
    }
    button {
      text-align: center;
    }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    #header {
      background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
        no-repeat;
        background-attachment: scroll!important;
    }
    #header-title {
      padding-top: 80vh;
      text-align: center;
      margin: auto;
    }
    #more {
      display: none !important;
    }
    #header-in {
      height: 20vh;
    }
    #header-in-title {
      padding-top: 12vh;
      text-align: center;
    }
    #header-in-title h1 {
      font-size: 2em;
    }
    button {
      text-align: center;
    }
}


@media (max-width: 424px) {
  #header {
    background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
      no-repeat;
      background-attachment: fixed;
  }
  #header-title {
    padding-top: 70vh;
    text-align: center;
    margin: auto;
  }
  #more {
    display: none !important;
  }
  #header-in {
    height: 28vh;
  }
  #header-in-title {
    padding-top: 12vh;
    text-align: center;
  }
  #header-in-title h1 {
    font-size: 2em;
  }
  button {
    text-align: center;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  #header {
    background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
      no-repeat;
      background-attachment: fixed;
  }
  #header-title {
    padding-top: 75vh;
    text-align: center;
    margin: auto;
  }
  #more {
    display: none !important;
  }
  #header-in {
    height: 28vh;
  }
  #header-in-title {
    padding-top: 12vh;
    text-align: center;
  }
  #header-in-title h1 {
    font-size: 2em;
  }
  button {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #header {
    background: url(assets/images/bg_website_bl_sm.jpg) center top / cover
      no-repeat;
      background-attachment: fixed;
  }
  #header-title {
    padding-top: 80vh;
    text-align: center;
    margin: auto;
  }
  #more {
    display: none !important;
  }
  #header-in {
    height: 20vh;
  }
  #header-in-title {
    padding-top: 12vh;
    text-align: center;
  }
  #header-in-title h1 {
    font-size: 2em;
  }
  button {
    text-align: center;
  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  #header-title {
    padding-top: 35vh;
    /* padding-left: 10vh; */
  }
  #more .rotate {
    margin-top: 15vh;
  }
}

@media (max-width: 1600px) and (min-width: 1200px) {
  #header-title {
    padding-top: 40vh;
    /* padding-left: 10vh; */
  }
  #more .rotate {
    margin-top: 22vh;
  }
}

@media (max-width: 1920px) and (min-width: 1600px) {
  #header-title {
    padding-top: 40vh;
    /* padding-left: 20vh; */
  }
  #header-title h1 {
    font-size: 3em;
  }
  #more .rotate {
    margin-top: 13vh;
  }
}

@media (min-width: 1920px) {
  #header-title {
    padding-top: 40vh;
    /* padding-left: 30vh; */
  }
  #header-title h1 {
    font-size: 3.5em;
  }
  #more .rotate {
    margin-top: 10vh;
  }
}

/* media queries end */

/* card-group start */

.home-cards {
  border: none !important;
  border-radius: 20px !important;
  transition-duration: 0.3s;
  /* min-height: 600px; */
  border: 1px solid #d0d8df !important;
}

.home-cards:hover {
  transform: translateY(-10px);
}

@media (min-width: 1200px) {
  .home-cards {
    height: 700px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home-cards {
    height: 600px;
  }
}

.card-title {
  font-weight: 700;
}

.card-group h4 {
  font-size: 3em;
  color: #50b4f2;
  text-align: center;
  padding-top: 50px;
}

/* card-group end */

/* check start */

.home-btn {
  transition: 0.1s !important;
  background-color: #50b4f2 !important;
  border: none !important;
  border-radius: 20px !important;
}

.home-btn:hover {
  background-color: #58bbf8e6 !important;
  box-shadow: 0px 5px 15px -9px #50b4f2;
}

.home-btn:active {
  transform: translateY(3px);
}

/* check end */

/* scroll to top button start */

.js .cd-top--fade-out {
  opacity: 0.5;
}

.js .cd-top--is-visible {
  visibility: visible;
  opacity: 1;
}

.js .cd-top {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s, background-color 0.3s;
}

.cd-top {
  position: fixed;
  bottom: 20px;
  bottom: var(--cd-back-to-top-margin);
  right: 20px;
  right: var(--cd-back-to-top-margin);
  display: inline-block;
  height: 40px;
  height: var(--cd-back-to-top-size);
  width: 40px;
  width: var(--cd-back-to-top-size);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05) !important;
  background: url(https://res.cloudinary.com/dxfq3iotg/image/upload/v1571057658/cd-top-arrow.svg)
    no-repeat center 50%;
  background-color: rgba(80, 180, 242, 0.9);
  background-color: hsla(
    var(--cd-color-3-h),
    var(--cd-color-3-s),
    var(--cd-color-3-l),
    0.8
  );
}

/* scroll to top button end */

/* Explanation type */
.tab-link {
  cursor: pointer;
  font-family: 'Encode Sans', sans-serif !important;
  color: #495057;
}

.tab-link:hover {
  color: #495057;
  
}

/* loader styling start */

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1010;
}

/* loader styling end */

/* Details page styling start */

.details-avatar {
  margin-top: -50px;
  margin-left: 20px;
}

@media (max-width: 425px) {
  .details-avatar {
    display: block;
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 426px) and (max-width: 1024px) {
  .details-username {
    margin-left: 60px;
  }
}

/* Details page styling end */
