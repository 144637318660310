.category-titles {
	font-family: 'Goldman', cursive;
	color: #343a40;
}

.card-progress {
	width: 5rem!important;
}
.category-row {
    text-align: center !important;
}

.category-card {
    text-align: left;
    transition-duration: 0.4s;
	border-radius: 20px !important;
    padding: 2rem;
    color: #343a40;
    height: 100%;
	background-color: rgb(250, 250, 250);
}

.green-bg-card {
    border: solid 2px rgba(4,217,196,0.6);
}

.green-bg-card:hover {
	box-shadow: 0px 0px 18px 0px rgba(2,94,115,0.4);
}

.red-bg-card {
    border: solid 2px rgba(242,100,68,0.6);
}

.red-bg-card:hover {
	box-shadow: 0px 0px 18px 0px rgba(166,76,68,0.4);
}

#green-dot {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 50px;
	background-color: rgba(4,217,196,0.6);
}

#red-dot {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 50px;
	background-color: rgba(242,100,68,0.6);
}

.round-btn {
	border-radius: 20px !important;
}
