#footer {
	font-family: "Roboto", sans-serif;
	font-weight: 100;
	color: white;
}

#footer .alert-link {
	font-family: 'Encode Sans', sans-serif;
	font-weight: 100;
}

#footer .alert-link a:hover {
	font-family: 'Encode Sans', sans-serif;
}

#footer .row {
	background-color: #012840;
}

#footer img {
	height: 40px;
	/* padding: 20px; */
}

#footer ul {
	display: inline-flex;
	list-style-type: none;
	/* padding: 0; */
}

#footer ul li {
	padding-right: 20px;
}

/* Scroll top button */
.toTopBtnHidden {
	display: none;
}

.toTopBtn {
	position: fixed;
	bottom: 26px;
	right: 39px;
	z-index: 98;
	padding: 21px;
	background-color: rgba(80, 180, 242, 0.9);
	transition: 0.3s !important;
}

.toTopBtn:hover {
	background-color: rgba(80, 180, 242, 1);
	transform: translateY(4px);
}