#navigation .img-fluid {
	max-height: 70px;
}

#navigation a {
	font-family: "Roboto", sans-serif;
	font-size: 1.5em;
	font-weight: 700;
	color: white;
}

.navbar {
	transition-duration: 0.3s;
}

.navbar.scrolled {
	/* background: rgba(28,194,244,0.9); */
	background: rgba(80, 180, 242, 0.9);
	transition: background 300ms linear;
}

.navbar.blue {
	/* background: rgba(28,194,244,0.9); */
	background: rgba(80, 180, 242, 0.9);
	transition: background 300ms linear;
}

.navbar.scrolled:hover {
	background: rgba(80, 180, 242, 1);
	transition: background 300ms linear;	
}

.nav-link {
	transition: 0.2s;
    font-family: 'Goldman', cursive !important;
}

.nav-link:hover {
	letter-spacing: 1px;
	font-weight: 700 !important;
}

#mainNavbar .navbar-toggler {
	outline: 0;
	border: 0;
}

/* animated burger menu navbar icon */
.collapsing {
	background-color: transparent !important;
}

.animated-icon {
	width: 30px;
	height: 20px;
	position: relative;
	margin: 0px;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
}

.animated-icon span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	/* border-radius: 9px; */
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

.animated-icon span {
	background: white;
}

.animated-icon span:nth-child(1) {
	top: 0px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.animated-icon span:nth-child(2) {
	top: 10px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.animated-icon span:nth-child(3) {
	top: 20px;
	-webkit-transform-origin: left center;
	-moz-transform-origin: left center;
	-o-transform-origin: left center;
	transform-origin: left center;
}

.animated-icon.open span:nth-child(1) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 0px;
	left: 8px;
}

.animated-icon.open span:nth-child(2) {
	width: 0%;
	opacity: 0;
}

.animated-icon.open span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	top: 21px;
	left: 8px;
}

.header-btn {
	border-radius: 20px !important;
	transition: 0.3s !important;
}

.header-btn:hover {
	transform: translateY(2px);
	box-shadow: 0px 4px 15px -5px #fff;
}
